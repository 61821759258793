<template>
  <v-container fluid>
    <v-row>
      <v-tabs centered grow background-color="lightBack" v-model="tab">
        <v-tab key="company">Company</v-tab>
        <v-tab key="employees">Employees</v-tab>
      </v-tabs>
    </v-row>
    <v-row align="start" justify="center" class="pt-5">
      <v-col cols="2" class="mr-2"> <p class="text-h6">Year:</p></v-col>
      <v-col cols="4" lg="2">
        <v-select :items="selectableYears" v-model="selected" dense outlined />
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item key="company">
        <v-row justify="center">
          <v-col md="10" sm="10" lg="10">
            <YearBillableHoursCard cardHeight="400" :year="selected" />
          </v-col>
          <v-col md="10" sm="10" lg="10">
            <YearNonBillableHoursCard cardHeight="500" :year="selected" />
          </v-col>
          <!-- <v-col cols="10" md="5" sm="10">
          <YearSumCustomerCard/>
        </v-col>
        <v-col cols="10" md="5" sm="10">
          <YearSumCustomerCard/>
        </v-col> -->
        </v-row>
      </v-tab-item>
      <v-tab-item key="employees">
        <v-row justify="center">
          <v-col md="10" sm="10" lg="10">
            <YearBillableHoursUserCard cardHeight="500" :year="selected" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  components: {
    YearBillableHoursCard: () =>
      import("@/components/admin/reportCards/yearBillableHoursCard.vue"),
    YearBillableHoursUserCard: () =>
      import("@/components/admin/reportCards/yearBillableHoursUserCard.vue"),
    YearNonBillableHoursCard: () =>
      import("@/components/admin/reportCards/yearNonBillableHoursCard.vue"),
  },
  data: () => ({
    selectableYears: ["2021", "2022", "2023", "2024", "2025"],
    selected: "2023",
    tab: "company",
    userId: null,
  }),
};
</script>

<style>
</style>