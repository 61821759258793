<template>
    <Report />
</template>

<script>
import Report from "@/components/admin/Report.vue"
export default {
    name: "reportView",
    components: {
        Report,
    },
}
</script>